import { Injectable } from '@angular/core';
@Injectable({
	providedIn: 'root'
})
export class Helper {

	getUserData() {

		return JSON.parse(sessionStorage.getItem("loginUser")!);
	}

	getUserConfigData() {

		return JSON.parse(sessionStorage.getItem("loginUserToken")!);
	}

	// getDate(date) {

	//   var now = new Date(date);
	//   var y = now.getFullYear();
	//   var m = now.getMonth() + 1;
	//   var d = now.getDate();
	//   return y + '-' + (m < 10 ? '0' : '') + m + '-' + (d < 10 ? '0' : '') + d;
	// }

	// getCompanyImgUrl() {
	//   return this.getBaseUrl() + 'uploads/company/';
	// }

	getVersion() {
		return '1.79';
	}

	getLiveDeploy() {
		return 1;
	}

	getBaseUrl() {
		if (window.location.hostname === "daytrend.local") { // local Docker
			return 'http://api.daytrend.local/api/';
		} else if (window.location.host == 'localhost:4200' || window.location.host.includes('4200')) { // localhost Windows
			return 'http://127.0.0.1:8000/api/'
		} else {
			if (window.location.host == "marketplace.timeflow.it") {
				return 'https://mkt-api.timeflow.it/api/';
			} else if (window.location.host.startsWith("stage.") || window.location.host.startsWith("pre-prod.") || window.location.host.startsWith("prod.")) {
				return 'https://api.' + window.location.host + '/api/';
			} else {
				return 'https://mkt-api.timeflow.it/api/';
			}
		}
	}
}
